@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.circle-port {
  width: 1px;
  height: 1px;
  border-radius: 1px;
  background: #898d9c;
  border: 1px solid #edeff5;
  cursor: pointer;
  position: absolute;
  top: 14px;
}
.circle-port:hover {
  background: #bbb;
  transition: background 200ms ease;
}

.circle-port-left {
  left: -18px;
}
.circle-port-right {
  right: -18px;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  background: #e4efef;
}

::-webkit-scrollbar-thumb {
  background: #1d388f61;
  border-radius: 2px;
}

/* 
  START React querybuilder styles overriding
*/
.ruleGroup {
  background: #FFFFFF !important;
  border-width: 0px !important;
  padding: 0px !important;
}

.ruleGroup .ruleGroup {
  background: #DDD4FA !important;
  padding: 0.5rem !important;
}


/* 
.ruleGroup-addRule {
  border-radius: 4px;
  border: 1px solid #7250e6;
  background: #ffffff;
  color: #7250e6;
  font-size: 14px;
  font-weight: 400;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-right: 10px;
}

.ruleGroup-addGroup {
  border-radius: 4px;
  border: 1px solid #7250e6;
  background: #ffffff;
  color: #7250e6;
  font-size: 14px;
  font-weight: 400;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-right: 10px;
}

.rule-remove, .ruleGroup-remove {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #F3F3F3;
  color: #8F8F8F;
  font-size: 18px;
} */
/* 
  END React querybuilder styles overriding
*/
